@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  position: relative;
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  background-color: #18191a;
  color: #fff;
}
body {
  height: 100;
}
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e4e4e67;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e4e4e67;
}

img {
  width: 100%;
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.register-card-design{
  background: radial-gradient( 50% 50% at 50% 50%, rgba(106, 104, 239, 0.24) 0%, rgba(38, 29, 48, 0.24) 100% );
    border: 1px solid #ffffff1e;
  color: #fff;
  border-radius: 20px;
  padding: 20px 0px;
  margin-top: 30px;
}
.banner-new-top-images-1{
  width: 40%;
  height: auto;
  margin: auto;
  margin-bottom: 2vh;
}
.banner-new-top-new-1{
  background-color: #103a82;
}
/* / / ============ Responsive section ============ / / */
/* / / ============ DESKTOP-VIEW ============ / / */
@media all and (min-width: 992px) {

  .floating-nav-link-top{
    position: absolute;
    font-size: 13px;
  }

  .bottom-footer-new-icons{
    background: linear-gradient(#171eae -90%,#0a0041 60%) padding-box,linear-gradient(0deg,#171eae,#171eae) border-box;
    border-radius: 10px;
    border: 1px solid transparent;
    width: 45px;
    height: 45px;
    justify-content: center;
    color: #97979e;
    text-decoration: none;
    box-shadow: 0 0 40px 0 rgba(23,30,173,.3215686274509804);
    padding: 8px;
  }
  .footer-anchor-tag{
    font-size: 1.4rem;
    color: #5a7cf6;
  }
  .footer-anchor-tag:hover{
   text-decoration: underline;
  }
  
  .footer-social-icons {
    font-size: 2rem;
    font-weight: 800;
  }
  .hero-section-footer {
    border-top: 1px solid #ffffff2c;
  }
  .hero-section-sixth-banner .accordion {
    --bs-accordion-btn-icon-width: 1.8rem;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed)::after {
    background-image: url("./assets/images/Group2.png");
  }
  .hero-section-sixth-banner .accordion-button::after {
    background-image: url("./assets/images/Group.png");
  }
  .hero-section-sixth-banner .accordion-body {
    color: #fff;
  }
  .hero-section-sixth-banner .accordion-item {
    background-color: transparent;
    border: none;
  }
  .hero-section-sixth-banner .accordion-button {
    background-color: #333333;
    margin-bottom: 2px;
    color: #fff;
    border-radius: 0px !important;
    padding: 30px 25px;
    box-shadow: none !important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 40px;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed) {
    background-color: #333333;
    box-shadow: none;
    color: #fff;
  }
  .hero-section-fifth-banner img {
    height: auto;
    width: 300px;
    margin: auto;
  }
  .banner-text-5 {
    font-size: 1.8rem;
    font-weight: 800;
  }
  .fifth-banner-card-2 {
    background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #ffc9da 100%);
  }
  .fifth-banner-card-1 {
    background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #f1618f 100%);
  }
  .hero-section-fifth-banner .card {
    height: 100%;
    padding: 40px 60px !important;
    border-radius: 30px;
  }
  .banner-text-4 {
    font-size: 1.3rem;
  }
  .banner-text-3 {
    font-size: 3rem;
    font-weight: 800;
  }
  .hero-section-fourth-banner .card {
    padding: 140px 80px;
    background-image: url("./assets/images/works2.png");
    background-size: 100% 100%;
    background-color: transparent;
  }

  .recent-border-box {
    padding: 16px 0px;
    border-bottom: 1px solid #592b4d;
  }
  .recent-text-3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
  }
  .recent-text-2 {
    background-color: #f1618f;
    padding: 6px 20px;
    border-radius: 20px;
  }
  .recent-text-1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
  }
  .recent-icon-1 {
    font-size: 24px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #f1658d 2.32%, #ee9b5b 97.93%);
    border-radius: 50px;
  }
  .recent-coin-image {
    width: 70px;
    height: 70px;
  }
  .hero-section-third-banner .card {
    background: linear-gradient(
      180deg,
      rgba(235, 98, 133, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    color: #fff;
    padding: 20px 20px;
    border-radius: 20px;
  }
  .overrall-search-id-section {
    border: 1px solid #9c3374;
    padding: 2px 10px;
    border-radius: 20px;
  }
  .dashboard-search-section .form-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #fff;
  }
  .tree-person-icon {
    font-size: 30px;
    margin-right: 4px;
  }
  .member-view-box {
    /* width: 220px; */
    background: #592b4d;
    border: 1px solid #ffffff2c;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
  }
  .dashboard-text-9 {
    font-size: 1.6rem;
    font-weight: 800;
  }
  .dashboard-text-8 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .dashboard-text-7 {
    font-size: 1.6rem;
    margin-bottom: 0px;
  }
  .dashboard-text-6 {
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 8px;
  }
  .dashboard-text-5 {
    font-size: 16px;
    font-weight: 800;
  }
  .dash-border-bottom {
    border-bottom: 1px solid #ffbbd646;
    padding-bottom: 10px;
  }
  .dashboard-text-4 {
    font-size: 1.6rem;
    font-weight: 800;
    margin-bottom: 0px;
  }
  .dashboard-text-4-4{
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 0px;
  }
  .dashboard-values-design .dash-new-card {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #5b2145 0%,
      rgba(38, 29, 48, 0.85) 100%
    );
    border: 1px solid #ffffff2c;
    color: #fff;
    border-radius: 20px;
  }
  .border-bottom-referral {
    border-bottom: 1px solid #f558976e;
    padding-bottom: 6px;
  }
  .copy-button-dashboard {
    background-color: #ffffff;
    color: #000;
    text-transform: uppercase;
    border: 0px;
    border-radius: 8px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 20px;
    padding: 1px 6px;
    font-weight: 900;
  }
  .dashboard-text-2 {
    font-size: 16px;
    font-weight: 800;
  }
  .dashboard-text-3:hover {
    text-decoration: underline;
  }
  .dashboard-text-1 {
    font-size: 14px;
    font-weight: 600;
  }
  .dashboard-top-navbar-design {
    background: #f15b9930;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .even-card-margin-bottom {
    margin-top: 100px;
  }
  .banner-text-2 {
    font-size: 3rem;
    font-weight: 800;
    background: linear-gradient(90deg, #f1658d 2.32%, #ee9b5b 97.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .hero-section-second-banner {
    padding-top: 100px;
    padding-bottom: 200px;
  }
  .hero-section-second-banner .card {
    color: #fff;
    padding: 3vw 2vw;
    padding-bottom: 200px;
    background-image: url("./assets/images/global-decentralized-ecosystem.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 230px;
    border-radius: 40px;
    transition: 0.2s; /* Animation */
    width: 95%;
    height: 95% !important;
  }
  .hero-section-second-banner .card:hover {
    width: 100%;
    height: 100% !important;
    background-size: 340px;
  }
  .hero-section-second-banner .card-title {
    font-size: 2.6rem;
    background: linear-gradient(90deg, #ffac43 2.32%, #8131e9 58.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    margin-bottom: 20px;
    width: 90%;
  }
  .hero-section-second-banner .card-text {
    font-size: 1.3rem;
    width: 30vw;
  }
  .glow-bg-1 {
    position: absolute;
    width: 40%;
    height: 70%;
    top: 15%;
    right: 10%;
    border-radius: 50%;
    /* filter: blur(100px); */
  }
  .banner-globe-image {
    width: 84%;
    height: 84%;
    margin: auto;
    z-index: 9;
    position: relative;
  }
  .banner-text-1 {
    font-size: 3.4rem;
    font-weight: 800;
  }
  .banner-min-height-desktop {
    min-height: 85vh;
    margin-top: 1vh;
    background: linear-gradient(
      180deg,
      rgba(239, 179, 255, 0.329) 0%,
      rgba(255, 205, 174, 0.253) 100%
    );
    border-radius: 20px;
    padding-left: 4vh;
    backdrop-filter: blur(55px);
    -webkit-backdrop-filter: blur(55px);
  }
  .main-nav-connect-button-icon {
    font-size: 18px;
    position: relative;
    top: -1px;
  }
  .navbar-brand img {
    width: 60px;
    height: auto;
  }
  .main-nav-connect-button:hover {
    color: #fff;
  }
  .main-nav-connect-button {
    background-color: #f55897;
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 16px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 14px;
    padding: 10px 20px !important;
    font-weight: 900;
  }
  .main-banner-join-button {
    background-color: #f55897;
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 50px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 20px;
    line-height: 72px;
    padding: 20px 53px;
    font-weight: 900;
  }
}

/* / / ============ MOBILE-VIEW ============ / / */

@media (max-width: 991px) {
  .bottom-footer-new-icons{
    background: linear-gradient(#171eae -90%,#0a0041 60%) padding-box,linear-gradient(0deg,#171eae,#171eae) border-box;
    border-radius: 10px;
    border: 1px solid transparent;
    width: 45px;
    height: 45px;
    justify-content: center;
    color: #97979e;
    text-decoration: none;
    box-shadow: 0 0 40px 0 rgba(23,30,173,.3215686274509804);
    padding: 8px;
  }
  .footer-anchor-tag{
    font-size: 0.6rem;
    color: #5a7cf6;
  }
  .footer-anchor-tag:hover{
   text-decoration: underline;
  }
  
  .navbar-toggler-icon{
    width: auto;
    height: auto;
  }
  .navbar-toggler-icon-new{
    font-size: 2rem;
  }
  .navbar-toggler{
    border: 1px solid #FFF;
    box-shadow: none !important;
  }
  .navbar-toggler-icon{
    font-size: 2rem;
    background-image: none !important;
  }
  .w-75 {
    width: 100% !important;
  }
  .footer-social-icons {
    font-size: 2rem;
    font-weight: 800;
  }
  .hero-section-footer {
    border-top: 1px solid #ffffff2c;
  }
  .hero-section-sixth-banner .accordion {
    --bs-accordion-btn-icon-width: 1.8rem;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed)::after {
    background-image: url("./assets/images/Group2.png");
  }
  .hero-section-sixth-banner .accordion-button::after {
    background-image: url("./assets/images/Group.png");
  }
  .hero-section-sixth-banner .accordion-body {
    color: #fff;
  }
  .hero-section-sixth-banner .accordion-item {
    background-color: transparent;
    border: none;
  }
  .hero-section-sixth-banner .accordion-button {
    background-color: #333333;
    margin-bottom: 2px;
    color: #fff;
    border-radius: 0px !important;
    padding: 30px 25px;
    box-shadow: none !important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 40px;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed) {
    background-color: #333333;
    box-shadow: none;
    color: #fff;
  }
  .hero-section-fifth-banner img {
    height: auto;
    width: 110px !important;
    margin: auto;
  }
  .banner-text-5 {
    font-size: 1.8rem;
    font-weight: 800;
  }
  .fifth-banner-card-2 {
    background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #ffc9da 100%);
  }
  .fifth-banner-card-1 {
    background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #f1618f 100%);
  }
  .hero-section-fifth-banner .card {
    height: 100%;
    padding: 40px 60px !important;
    border-radius: 30px;
  }
  .banner-text-4 {
    font-size: 1rem;
  }
  .banner-text-3 {
    font-size: 2rem;
    font-weight: 800;
  }
  .hero-section-fourth-banner .card {
    padding: 20px 10px;
    background-image: url("./assets/images/works2.png");
    background-size: 100% 100%;
    background-color: transparent;
  }

  .recent-border-box {
    padding: 16px 0px;
    border-bottom: 1px solid #592b4d;
  }
  .recent-text-3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
  }
  .recent-text-2 {
    background-color: #f1618f;
    padding: 2px 10px;
    border-radius: 20px;
  }
  .recent-text-1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
  }
  .recent-icon-1 {
    font-size: 20px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #f1658d 2.32%, #ee9b5b 97.93%);
    border-radius: 50px;
  }
  .recent-coin-image {
    width: 40px;
    height: 40px;
  }
  .hero-section-third-banner .card {
    background: linear-gradient(
      180deg,
      rgba(235, 98, 133, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    color: #fff;
    padding: 20px 20px;
    border-radius: 20px;
  }
  .overrall-search-id-section {
    border: 1px solid #9c3374;
    padding: 2px 10px;
    border-radius: 20px;
  }
  .dashboard-search-section .form-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #fff;
  }
  .tree-person-icon {
    font-size: 30px;
    margin-right: 4px;
  }
  .member-view-box {
    width: 220px;
    background: #592b4d;
    border: 1px solid #ffffff2c;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
  }
  .dashboard-text-9 {
    font-size: 1.6rem;
    font-weight: 800;
  }
  .dashboard-text-8 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .dashboard-text-7 {
    font-size: 1.6rem;
    margin-bottom: 0px;
  }
  .dashboard-text-6 {
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 8px;
  }
  .dashboard-text-5 {
    font-size: 16px;
    font-weight: 800;
  }
  .dash-border-bottom {
    border-bottom: 1px solid #ffbbd646;
    padding-bottom: 10px;
  }
  .dashboard-text-4 {
    font-size: 1.6rem;
    font-weight: 800;
    margin-bottom: 0px;
  }
  .dashboard-text-4-4{
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 0px;
  }
  .dashboard-values-design .dash-new-card {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #5b2145 0%,
      rgba(38, 29, 48, 0.85) 100%
    );
    border: 1px solid #ffffff2c;
    color: #fff;
    border-radius: 20px;
  }
  .border-bottom-referral {
    border-bottom: 1px solid #f558976e;
    padding-bottom: 6px;
  }
  .copy-button-dashboard {
    background-color: #ffffff;
    color: #000;
    text-transform: uppercase;
    border: 0px;
    border-radius: 8px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 20px;
    padding: 1px 6px;
    font-weight: 900;
  }
  .dashboard-text-2 {
    font-size: 16px;
    font-weight: 800;
  }
  .dashboard-text-3:hover {
    text-decoration: underline;
  }
  .dashboard-text-1 {
    font-size: 14px;
    font-weight: 600;
  }
  .dashboard-top-navbar-design {
    background: #f15b9930;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .banner-text-2 {
    font-size: 1.8rem !important;
    font-weight: 800;
    background: linear-gradient(90deg, #f1658d 2.32%, #ee9b5b 97.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .hero-section-second-banner {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hero-section-second-banner .card {
    color: #fff;
    padding: 3vw 2vw;
    padding-bottom: 200px;
    background-image: url("./assets/images/global-decentralized-ecosystem.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 230px;
    border-radius: 40px;
    transition: 0.2s; /* Animation */
  }
  .hero-section-second-banner .card-title {
    font-size: 1.6rem !important;
    background: linear-gradient(90deg, #ffac43 2.32%, #8131e9 58.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    margin-bottom: 20px;
    width: 90%;
  }
  .hero-section-second-banner .card-text {
    font-size: 1rem !important;
  }

  .banner-globe-image {
    width: 60%;
    height: 60%;
    margin: auto;
    z-index: 9;
    position: relative;
    margin-bottom: 40px !important;
  }
  .banner-text-1 {
    font-size: 1.6rem !important;
    font-weight: 800;
  }
  .banner-min-height-desktop {
    background: linear-gradient(
      180deg,
      rgba(239, 179, 255, 0.329) 0%,
      rgba(255, 205, 174, 0.253) 100%
    );
    border-radius: 20px;
    padding: 2vw;
    backdrop-filter: blur(55px);
    -webkit-backdrop-filter: blur(55px);
  }
  .main-nav-connect-button-icon {
    font-size: 18px;
    position: relative;
    top: -1px;
  }
  .navbar-brand img {
    width: 60px;
    height: auto;
  }
  .main-nav-connect-button:hover {
    color: #fff;
  }
  .main-nav-connect-button {
    background-color: #f55897;
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 16px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 14px;
    padding: 10px 20px !important;
    font-weight: 900;
    text-align: center;
  }
  .main-banner-join-button {
    background-color: #f55897;
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 50px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 20px;
    line-height: 72px;
    padding: 10px 13px;
    font-weight: 900;
  }
}

.genealogy-scroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.genealogy-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}
.genealogy-scroll::-webkit-scrollbar-thumb {
  background: #212121;
  border-radius: 10px;
  transition: 0.5s;
}
.genealogy-scroll::-webkit-scrollbar-thumb:hover {
  background: #d5b14c;
  transition: 0.5s;
}

/*----------------genealogy-tree----------*/
.genealogy-body {
  white-space: nowrap;
  overflow-y: hidden;
  padding: 50px;
  padding-top: 10px;
  text-align: center;
}
.genealogy-tree {
  display: inline-block;
}
.genealogy-tree ul {
  padding-top: 20px;
  position: relative;
  padding-left: 0px;
  display: flex;
  justify-content: center;
}
.genealogy-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 15px;
}
.genealogy-tree li::before,
.genealogy-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #ccc;
  width: 50%;
  height: 18px;
}
.genealogy-tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #ccc;
}
.genealogy-tree li:only-child::after,
.genealogy-tree li:only-child::before {
  display: none;
}
.genealogy-tree li:only-child {
  padding-top: 0;
}
.genealogy-tree li:first-child::before,
.genealogy-tree li:last-child::after {
  border: 0 none;
}
.genealogy-tree li:last-child::before {
  border-right: 2px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.genealogy-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}
.genealogy-tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 20px;
}
.genealogy-tree li a {
  text-decoration: none;
  color: #fff;
  font-family: arial, verdana, tahoma;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.genealogy-tree li a:hover + ul li::after,
.genealogy-tree li a:hover + ul li::before,
.genealogy-tree li a:hover + ul::before,
.genealogy-tree li a:hover + ul ul::before {
  border-color: #fbba00;
}
 .connect-metamask-button{
  margin-top: 15px;
  background-color: transparent;
}
.modal-header {
  border-bottom:none;
}
.modal-dialog{
  top:30%
}
.modal-content{
  backdrop-filter: blur( 20px );
  background:#10001f !important; 
}
.modal-header .btn-close{
  width: 8px;
  height: 8px;
  padding: 1px 1px;
  background-color: #ffffff !important;
}

.dashboard-active {
  border:1px solid #6a68ef !important;
  cursor: pointer;
  transform: scale(0.88);
}

.dashboard-values-design .dash-new-card:hover{
  border:1px solid #6a68ef !important;  
}
/* .main-nav-connect-button{

} */
.main-nav-connect-button:hover{
 opacity:0.6;
 cursor:pointer;
}
.join-main-button:hover{
 opacity:0.7;
 cursor:pointer;

}


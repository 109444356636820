@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  position: relative;
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  background-color: #1b1049;
  color: #fff;
}
body {
  height: 100;
}
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e4e4e67;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e4e4e67;
}

img {
  width: 100%;
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* / / ============ Responsive section ============ / / */
/* / / ============ DESKTOP-VIEW ============ / / */
@media all and (min-width: 992px) {
  .col-lg-3 {
    position: -webkit-sticky;
    position: sticky;
    top: 5%;
  }
  .dasboard-tabs-section .nav-link {
    color: #fff;
    font-weight: 1000;
    text-transform: uppercase;
    font-size: 22px;
  }
  .dasboard-tabs-section .nav-pills .nav-link.active,
  .dasboard-tabs-section .nav-pills .show > .nav-link {
    background-color: transparent;
    color: #6a68ef;
  }
  .form-control-modal {
    background-color: transparent;
    box-shadow: none;
    box-shadow: none !important;
    border: 1px solid #ffffff54;
    color: #fff !important;
  }
  .form-control-modal:focus {
    background-color: transparent;
    border: 1px solid #ffffff54;
  }
  .modal-border-bottom {
    border-bottom: 1px solid #fff;
  }
  .btn-white-close {
    font-weight: 800;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    background-color: transparent;
    border: none;
  }
  .modal-content {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: #49268770;
    border: 1px solid #bea4eb54;
  }
  .network-text-2 {
    font-size: 1.6rem;
    text-transform: uppercase;
  }
  .network-text-1 {
    font-size: 2.4rem;
    text-transform: uppercase;
  }
  .network-card-box {
    width: 400px;
    margin: auto;
    background: linear-gradient(180deg, #4a2687 0%, #3d3a9a 100%);
    border: none;
    color: #fff;
    padding: 40px;
    cursor: pointer;
  }
  .hero-section-network-banner .dropdown-menu {
    border-radius: 0px;
    padding: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 12rem;
  }
  .hero-section-network-banner .dropdown-menu .nav-link {
    font-weight: 800;
    padding: 10px;
  }
  .hero-section-network-banner .dropdown-network-img {
    width: 30px;
    height: auto;
  }
  .btn-network-select {
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    margin-top: 80px;
    margin-bottom: 14vh;
    box-shadow: none;
    outline: none;
    border: none;
    width: 12rem;
  }
  .btn-network-select.dropdown-toggle::after {
    margin-left: 2.255em;
  }
  .footer-social-icons {
    font-size: 2rem;
    font-weight: 800;
  }
  .hero-section-footer {
    border-top: 1px solid #ffffff2c;
  }
  .hero-section-sixth-banner .accordion {
    --bs-accordion-btn-icon-width: 1.8rem;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed)::after {
    /* background-image: url("./assests/images/Group2.png"); */
  }
  .hero-section-sixth-banner .accordion-button::after {
    /* background-image: url("./assests/images/Group.png"); */
  }
  .hero-section-sixth-banner .accordion-body {
    color: #fff;
  }
  .hero-section-sixth-banner .accordion-item {
    background-color: transparent;
    border: none;
  }
  .hero-section-sixth-banner .accordion-button {
    background: linear-gradient(
      180deg,
      rgba(45, 47, 118, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    margin-bottom: 2px;
    color: #fff;
    border-radius: 0px !important;
    padding: 30px 25px;
    box-shadow: none !important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 40px;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed) {
    background: linear-gradient(
      180deg,
      rgba(45, 47, 118, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    box-shadow: none;
    color: #fff;
  }
  .hero-section-fifth-banner img {
    height: auto;
    width: 300px;
    margin: auto;
  }
  .banner-text-5 {
    font-size: 1.8rem;
    font-weight: 800;
  }
  .fifth-banner-card-2 {
    background: radial-gradient(50% 50% at 50% 50%, #ffffff9d 0%, #7632b9 100%);
  }
  .fifth-banner-card-1 {
    background: radial-gradient(50% 50% at 50% 50%, #ffffff9d 0%, #5473e1 100%);
  }
  .hero-section-fifth-banner .card {
    height: 100%;
    padding: 40px 60px !important;
    border-radius: 30px;
  }
  .banner-text-4 {
    font-size: 1.3rem;
  }
  .banner-text-3 {
    font-size: 3rem;
    font-weight: 800;
  }
  .hero-section-fourth-banner .card {
    padding: 140px 80px;
    /* background-image: url("./assests/images/works2.png"); */
    background-size: 100% 100%;
    background-color: transparent;
  }

  .recent-border-box {
    padding: 16px 0px;
    border-bottom: 1px solid #ffffff33;
  }
  .recent-text-3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
  }
  .recent-text-2 {
    background-color: #5973ee;
    padding: 6px 20px;
    border-radius: 20px;
  }
  .recent-text-1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
  }
  .recent-icon-1 {
    font-size: 24px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    border-radius: 50px;
  }
  .recent-coin-image {
    width: 70px;
    height: 70px;
  }
  .hero-section-third-banner .card {
    background: linear-gradient(
      180deg,
      rgba(45, 47, 118, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    color: #fff;
    padding: 20px 20px;
    border-radius: 20px;
  }
  .overrall-search-id-section {
    border: 1px solid #6a68ef;
    padding: 2px 10px;
    border-radius: 20px;
  }
  .dashboard-search-section .form-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #fff;
  }
  .tree-person-icon {
    font-size: 30px;
    margin-right: 4px;
  }
  .member-view-box {
    /* width: 220px; */
    background: #6a68ef41;
    border: 1px solid #ffffff2c;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
  }
  .member-view-box .ms-auto {
    margin-left: 6px !important;
  }
  .dashboard-text-9 {
    font-size: 1.6rem;
    font-weight: 800;
  }
  .dashboard-text-8 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .dashboard-text-7 {
    font-size: 1.4rem;
    margin-bottom: 0px;
  }
  .dashboard-text-6 {
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 8px;
  }
  .dashboard-text-5 {
    /* font-size: 1.6rem; */
    font-size: 1rem;
    font-weight: 800;
  }
  .dash-border-bottom {
    border-bottom: 1px solid #ffbbd646;
    padding-bottom: 10px;
  }
  .dashboard-text-4 {
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: 0px;
  }
  .dashboard-values-design .dash-new-card {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(106, 104, 239, 0.24) 0%,
      rgba(38, 29, 48, 0.24) 100%
    );
    border: 1px solid #ffffff1e;
    color: #fff;
    border-radius: 20px;
  }
  .border-bottom-referral {
    border-bottom: 1px solid #ffbbd646;
    padding-bottom: 6px;
  }
  .copy-button-dashboard {
    background-color: #ffffff;
    color: #000;
    text-transform: uppercase;
    border: 0px;
    border-radius: 8px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 20px;
    padding: 1px 6px;
    font-weight: 900;
  }
  .dashboard-text-2 {
    font-size: 16px;
    font-weight: 800;
  }
  .dashboard-text-3:hover {
    text-decoration: underline;
  }
  .dashboard-text-1 {
    font-size: 14px;
    font-weight: 600;
  }
  .dashboard-top-navbar-design {
    background: #31364430;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .even-card-margin-bottom {
    margin-top: 100px;
  }
  .banner-text-2 {
    font-size: 3rem;
    font-weight: 800;
    background: linear-gradient(90deg, #a344f1 2.32%, #5a7cf6 97.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .hero-section-second-banner {
    padding-top: 100px;
    padding-bottom: 200px;
  }

  .hero-section-second-banner .card {
    color: #fff;
    padding: 3vw 2vw;
    padding-bottom: 200px;
    /* background-image: url("./assests/images/global-decentralized-ecosystem.png"); */
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 230px;
    border-radius: 40px;
    transition: 0.2s; /* Animation */
    width: 95%;
    height: 95% !important;
    background-color: transparent;
    margin-bottom: 0px;
    border: none;
  }
  .hero-section-second-banner .card::before {
    content: "";
    width: 100%;
    height: 100% !important;
    z-index: -99;
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 40px;
  }
  .banner-platform-card-1::before {
    background: linear-gradient(180deg, #37a9c2 0%, #924bee 100%);
  }
  .banner-platform-card-2::before {
    background: linear-gradient(180deg, #4559bd 0%, #7533ba 100%);
  }
  .banner-platform-card-3::before {
    background: linear-gradient(180deg, #4e3b79 0%, #362363 100%);
  }
  .banner-platform-card-4::before {
    background: linear-gradient(180deg, #2d2671 0%, #2d135a 100%);
  }
  .hero-section-second-banner .card .main-banner-join-button {
    background: #f8a54d;
    color: #000;
  }
  .hero-section-second-banner .card:hover {
    width: 100%;
    height: 100% !important;
    background-size: 340px;
  }
  .hero-section-second-banner .card-title {
    font-size: 2.6rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    width: 90%;
  }
  .hero-section-second-banner .card-text {
    font-size: 1.3rem;
    width: 30vw;
  }
  .glow-bg-1 {
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    position: absolute;
    width: 40%;
    height: 70%;
    top: 15%;
    right: 10%;
    border-radius: 50%;
    filter: blur(100px);
  }
  .banner-globe-image {
    width: 84%;
    height: 84%;
    margin: auto;
    z-index: 9;
    position: relative;
  }
  .banner-text-1 {
    font-size: 3.4rem;
    font-weight: 800;
  }
  .banner-min-height-desktop {
    min-height: 85vh;
    margin-top: 1vh;
    background: linear-gradient(
      180deg,
      rgba(83, 120, 237, 0.32) 0%,
      rgba(159, 66, 238, 0.38) 100%
    );
    border-radius: 20px;
    padding-left: 4vh;
    backdrop-filter: blur(55px);
    -webkit-backdrop-filter: blur(55px);
  }
  .main-nav-connect-button-icon {
    font-size: 18px;
    position: relative;
    top: -1px;
  }
  .navbar-brand img {
    width: 60px;
    height: auto;
  }
  .main-nav-connect-button:hover {
    color: #fff;
  }
  .main-nav-connect-button {
    background: linear-gradient(
      89.66deg,
      #6a68ef 7.4%,
      rgba(252, 88, 255, 0) 110.79%
    );
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px 20px !important;
    font-weight: 900;
  }
  .main-banner-join-button {
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 50px;
    font-size: 20px;
    line-height: 72px;
    padding: 20px 53px;
    font-weight: 900;
  }
}

/* / / ============ MOBILE-VIEW ============ / / */

@media (max-width: 991px) {
  .navbar-toggler-icon-new {
    font-size: 2rem;
  }
  .navbar-toggler {
    border: 1px solid #fff;
    box-shadow: none !important;
  }
  .navbar-toggler-icon {
    background-image: none !important;
  }
  .w-75 {
    width: 100% !important;
  }
  .dasboard-tabs-section .nav-link {
    color: #fff;
    font-weight: 1000;
    text-transform: uppercase;
    font-size: 22px;
  }
  .dasboard-tabs-section .nav-pills .nav-link.active,
  .dasboard-tabs-section .nav-pills .show > .nav-link {
    background-color: transparent;
    color: #6a68ef;
  }
  .form-control-modal {
    background-color: transparent;
    box-shadow: none;
    box-shadow: none !important;
    border: 1px solid #ffffff54;
    color: #fff !important;
  }
  .form-control-modal:focus {
    background-color: transparent;
    border: 1px solid #ffffff54;
  }
  .modal-border-bottom {
    border-bottom: 1px solid #fff;
  }
  .btn-white-close {
    font-weight: 800;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    background-color: transparent;
    border: none;
  }
  .modal-content {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: #49268770;
    border: 1px solid #bea4eb54;
  }
  .network-text-2 {
    font-size: 1.6rem;
    text-transform: uppercase;
  }
  .network-text-1 {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  .network-card-box {
    width: 100%;
    margin: auto;
    background: linear-gradient(180deg, #4a2687 0%, #3d3a9a 100%);
    border: none;
    color: #fff;
    padding: 40px;
    cursor: pointer;
  }
  .hero-section-network-banner .dropdown-menu {
    border-radius: 0px;
    padding: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 12rem;
  }
  .hero-section-network-banner .dropdown-menu .nav-link {
    font-weight: 800;
    padding: 10px;
  }
  .hero-section-network-banner .dropdown-network-img {
    width: 30px;
    height: auto;
  }
  .btn-network-select {
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    margin-top: 80px;
    margin-bottom: 8vh;
    box-shadow: none;
    outline: none;
    border: none;
    width: 12rem;
  }
  .btn-network-select.dropdown-toggle::after {
    margin-left: 2.255em;
  }
  .footer-social-icons {
    font-size: 2rem;
    font-weight: 800;
  }
  .hero-section-footer {
    border-top: 1px solid #ffffff2c;
  }
  .hero-section-sixth-banner .accordion {
    --bs-accordion-btn-icon-width: 1.8rem;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed)::after {
    /* background-image: url("./assests/images/Group2.png"); */
  }
  .hero-section-sixth-banner .accordion-button::after {
    /* background-image: url("./assests/images/Group.png"); */
  }
  .hero-section-sixth-banner .accordion-body {
    color: #fff;
  }
  .hero-section-sixth-banner .accordion-item {
    background-color: transparent;
    border: none;
  }
  .hero-section-sixth-banner .accordion-button {
    background: linear-gradient(
      180deg,
      rgba(45, 47, 118, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    margin-bottom: 2px;
    color: #fff;
    border-radius: 0px !important;
    padding: 30px 25px;
    box-shadow: none !important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 40px;
  }
  .hero-section-sixth-banner .accordion-button:not(.collapsed) {
    background: linear-gradient(
      180deg,
      rgba(45, 47, 118, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    box-shadow: none;
    color: #fff;
  }
  .hero-section-fifth-banner img {
    height: auto;
    width: 300px;
    margin: auto;
  }
  .banner-text-5 {
    font-size: 1.8rem;
    font-weight: 800;
  }
  .fifth-banner-card-2 {
    background: radial-gradient(50% 50% at 50% 50%, #ffffff9d 0%, #7632b9 100%);
  }
  .fifth-banner-card-1 {
    background: radial-gradient(50% 50% at 50% 50%, #ffffff9d 0%, #5473e1 100%);
  }
  .hero-section-fifth-banner .card {
    height: 100%;
    padding: 20px 30px !important;
    border-radius: 30px;
  }
  .banner-text-4 {
    font-size: 1.1rem;
  }
  .banner-text-3 {
    font-size: 3rem;
    font-weight: 800;
  }
  .hero-section-fourth-banner .card {
    padding: 10px 10px;
    /* background-image: url("./assests/images/works2.png"); */
    background-size: 100% 100%;
    background-color: transparent;
  }

  .recent-border-box {
    padding: 16px 0px;
    border-bottom: 1px solid #ffffff33;
  }
  .recent-text-3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
  }
  .recent-text-2 {
    background-color: #5973ee;
    padding: 6px 10px;
    border-radius: 20px;
  }
  .recent-text-1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
  }
  .recent-icon-1 {
    font-size: 18px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    border-radius: 50px;
  }
  .recent-coin-image {
    width: 25px;
    height: 25px;
  }
  .hero-section-third-banner .card {
    background: linear-gradient(
      180deg,
      rgba(45, 47, 118, 0.15) 0%,
      rgba(222, 112, 95, 0.15) 100%
    );
    color: #fff;
    padding: 20px 20px;
    border-radius: 20px;
  }
  .overrall-search-id-section {
    border: 1px solid #6a68ef;
    padding: 2px 10px;
    border-radius: 20px;
  }
  .dashboard-search-section .form-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #fff;
  }
  .tree-person-icon {
    font-size: 30px;
    margin-right: 4px;
  }
  .member-view-box {
    /* width: 220px; */
    background: #6a68ef41;
    border: 1px solid #ffffff2c;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
  }
  .member-view-box .ms-auto {
    margin-left: 6px !important;
  }
  .dashboard-text-9 {
    font-size: 1.6rem;
    font-weight: 800;
  }
  .dashboard-text-8 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .dashboard-text-7 {
    font-size: 1.6rem;
    margin-bottom: 0px;
  }
  .dashboard-text-6 {
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 8px;
  }
  .dashboard-text-5 {
    font-size: 1.2rem;
    font-weight: 800;
  }
  .dash-border-bottom {
    border-bottom: 1px solid #ffbbd646;
    padding-bottom: 10px;
  }
  .dashboard-text-4 {
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: 0px;
  }
  .dashboard-values-design .dash-new-card {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(106, 104, 239, 0.24) 0%,
      rgba(38, 29, 48, 0.24) 100%
    );
    border: 1px solid #ffffff1e;
    color: #fff;
    border-radius: 20px;
  }
  .border-bottom-referral {
    border-bottom: 1px solid #ffbbd646;
    padding-bottom: 6px;
  }
  .copy-button-dashboard {
    background-color: #ffffff;
    color: #000;
    text-transform: uppercase;
    border: 0px;
    border-radius: 8px;
    box-shadow: rgba(180, 180, 180, 0.4) 0px -1px 0px 0px inset;
    font-size: 20px;
    padding: 1px 6px;
    font-weight: 900;
  }
  .dashboard-text-2 {
    font-size: 16px;
    font-weight: 800;
  }
  .dashboard-text-3:hover {
    text-decoration: underline;
  }
  .dashboard-text-1 {
    font-size: 14px;
    font-weight: 600;
  }
  .dashboard-top-navbar-design {
    background: #31364430;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .banner-text-2 {
    font-size: 2.4rem;
    font-weight: 800;
    background: linear-gradient(90deg, #a344f1 2.32%, #5a7cf6 97.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .hero-section-second-banner {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .hero-section-second-banner .card {
    color: #fff;
    padding: 3vw 2vw;
    padding-bottom: 20px;
    /* background-image: url("./assests/images/global-decentralized-ecosystem.png"); */
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 80px;
    border-radius: 40px;
    transition: 0.2s; /* Animation */
    width: 100%;
    height: 100% !important;
    background-color: transparent;
    margin-bottom: 0px;
    border: none;
  }
  .hero-section-second-banner .card::before {
    content: "";
    width: 100%;
    height: 100% !important;
    z-index: -99;
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 40px;
  }
  .banner-platform-card-1::before {
    background: linear-gradient(180deg, #37a9c2 0%, #924bee 100%);
  }
  .banner-platform-card-2::before {
    background: linear-gradient(180deg, #4559bd 0%, #7533ba 100%);
  }
  .banner-platform-card-3::before {
    background: linear-gradient(180deg, #4e3b79 0%, #362363 100%);
  }
  .banner-platform-card-4::before {
    background: linear-gradient(180deg, #2d2671 0%, #2d135a 100%);
  }
  .hero-section-second-banner .card .main-banner-join-button {
    background: #f8a54d;
    color: #000;
  }
  .hero-section-second-banner .card:hover {
    width: 100%;
    height: 100% !important;
    background-size: 340px;
  }
  .hero-section-second-banner .card-title {
    font-size: 2rem;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
    width: 90%;
  }
  .hero-section-second-banner .card-text {
    font-size: 1.1rem;
  }
  .banner-globe-image {
    margin: auto;
    z-index: 9;
    position: relative;
  }
  .banner-text-1 {
    font-size: 2rem;
    font-weight: 800;
  }
  .banner-min-height-desktop {
    margin-top: 1vh;
    background: linear-gradient(
      180deg,
      rgba(83, 120, 237, 0.32) 0%,
      rgba(159, 66, 238, 0.38) 100%
    );
    border-radius: 20px;

    backdrop-filter: blur(55px);
    -webkit-backdrop-filter: blur(55px);
    padding-bottom: 2vh;
  }
  .main-nav-connect-button-icon {
    font-size: 18px;
    position: relative;
    top: -1px;
  }
  .navbar-brand img {
    width: 60px !important;
    height: auto !important;
  }
  .main-nav-connect-button:hover {
    color: #fff;
  }
  .main-nav-connect-button {
    background: linear-gradient(
      89.66deg,
      #6a68ef 7.4%,
      rgba(252, 88, 255, 0) 110.79%
    );
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px 20px !important;
    font-weight: 900;
    text-align: center;
    width: 100%;
  }
  .main-banner-join-button {
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    color: #fff;
    text-transform: uppercase;
    border: 0px;
    border-radius: 50px;
    font-size: 20px;
    line-height: 72px;
    padding: 14px 33px;
    font-weight: 900;
  }
}

.genealogy-scroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.genealogy-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}
.genealogy-scroll::-webkit-scrollbar-thumb {
  background: #212121;
  border-radius: 10px;
  transition: 0.5s;
}
.genealogy-scroll::-webkit-scrollbar-thumb:hover {
  background: #d5b14c;
  transition: 0.5s;
}

/*----------------genealogy-tree----------*/
.genealogy-body {
  white-space: nowrap;
  overflow-y: hidden;
  padding: 50px;
  padding-top: 10px;
  text-align: center;
}
.genealogy-tree {
  display: inline-block;
}
.genealogy-tree ul {
  padding-top: 20px;
  position: relative;
  padding-left: 0px;
  display: flex;
  justify-content: center;
}
.genealogy-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 15px;
}
.genealogy-tree li::before,
.genealogy-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #ccc;
  width: 50%;
  height: 18px;
}
.genealogy-tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #ccc;
}
.genealogy-tree li:only-child::after,
.genealogy-tree li:only-child::before {
  display: none;
}
.genealogy-tree li:only-child {
  padding-top: 0;
}
.genealogy-tree li:first-child::before,
.genealogy-tree li:last-child::after {
  border: 0 none;
}
.genealogy-tree li:last-child::before {
  border-right: 2px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.genealogy-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}
.genealogy-tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 20px;
}
.genealogy-tree li a {
  text-decoration: none;
  color: #fff;
  font-family: arial, verdana, tahoma;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.genealogy-tree li a:hover + ul li::after,
.genealogy-tree li a:hover + ul li::before,
.genealogy-tree li a:hover + ul::before,
.genealogy-tree li a:hover + ul ul::before {
  border-color: #fbba00;
}
.btn-connect-dropdown {
  border: none !important;
  background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
}

.form-control::placeholder {
  color: #C8C8C8;
}
